import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';
const { Paragraph, Text } = Typography;
import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';

const PHONE_NUMBER = '(818)995-8400';
const EMAIL = 'brenda@metroart.com';
const ADDRESS_NAME = 'Metro Art Sherman Oaks';
const ADDRESS_1 = '14140 Moorpark St.';
const ADDRESS_2 = 'Sherman Oaks, CA 91423';

const tours =[
    {
        name: '1BR, 1BA - 830 SF',
        url: 'https://my.matterport.com/show/?model=drxoZVnkoq5&play=1'
    },
    {
        name: '2BR, 2BA - 1135 SF',
        url: 'https://my.matterport.com/show/?model=vXn2CwbqiZR&play=1'
    },
    {
        name: '1BR, 1BA Loft - 1000 SF',
        url: 'https://my.matterport.com/show/?model=i5nLjnVpmQZ&play=1'
    },
    {
        name: '2BR, 2BA - 1370 SF',
        url: 'https://my.matterport.com/show/?model=E52a59YqYmG&play=1'
    }
];

const amentities = {
    sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
      <Paragraph><Text mark strong>Great location</Text> close to restaurants, shops & more!</Paragraph>
      <Paragraph>Included parking in a secure garage.</Paragraph>
      <Paragraph>Intercom entry and secure access.</Paragraph>
      <Paragraph>Professional <Text mark strong>&nbsp;on-site manager&nbsp;</Text>.</Paragraph>
      <Paragraph>Fully equipped <Text mark strong>fitness center</Text> with quality equipment.</Paragraph>
      <Paragraph><Text mark strong>Pool</Text> and large outdoor areas.</Paragraph>
      <Paragraph><Text mark strong>Electrical Vehicle Chargers</Text> Available</Paragraph>
      <Paragraph>Cat friendly, no dogs.</Paragraph>
    </div>,
    images: [
      {
        src: 'assets/web/common-1.jpg',
        caption: '',
        thumb: 'assets/web/common-1.jpg'
      },
      {
        src: 'assets/web/common-2.jpg',
        caption: '',
        thumb: 'assets/web/common-2.jpg'
      },
      {
        src: 'assets/web/common-3.jpg',
        caption: '',
        thumb: 'assets/web/common-3.jpg'
      },
      {
        src: 'assets/web/common-4.jpg',
        caption: '',
        thumb: 'assets/web/common-4.jpg'
      },
      {
        src: 'assets/web/common-5.jpg',
        caption: '',
        thumb: 'assets/web/common-5.jpg'
      },
      {
        src: 'assets/web/common-6.jpg',
        caption: '',
        thumb: 'assets/web/common-6.jpg'
      },
      {
        src: 'assets/web/common-7.jpg',
        caption: '',
        thumb: 'assets/web/common-7.jpg'
      },
      {
        src: 'assets/web/common-8.jpg',
        caption: '',
        thumb: 'assets/web/common-8.jpg'
      },
      {
        src: 'assets/web/common-9.jpg',
        caption: '',
        thumb: 'assets/web/common-9.jpg'
      }
    ]
}

const features = {
    sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
        <Paragraph>Spacious layouts with ample space (up to 1400 sf!)</Paragraph>
        <Paragraph>Bright, open floor plans build to <Text mark strong>condominium quality</Text>.</Paragraph>
        <Paragraph>Tall 9' to 12' ceilings.</Paragraph>
        <Paragraph><Text mark strong>Full-size washer and dryer</Text> in every unit.</Paragraph>
        <Paragraph><Text mark strong>Upgraded stainless steel appliances</Text>.</Paragraph>
        <Paragraph>Bright laminate wood flooring, custom self-closing wood cabinets.</Paragraph>
        <Paragraph><Text mark strong>Large balconies or patios</Text>.</Paragraph>
        <Paragraph>Spacious <Text mark strong>walk-in closets</Text></Paragraph>
        <Paragraph>Granite kitchen counters.</Paragraph>
    </div>,
    sentenceMore: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
        <Row gutter={24}>
            <Col sm={24} md={12}>
                <Divider>Our Apartments</Divider>
                <Paragraph>All of the above and...</Paragraph>
                <Paragraph>New carpet in bedroom areas</Paragraph>
                <Paragraph>Ceramic tile in bathrooms</Paragraph>
                <Paragraph>Oversized bathrooms with travertine counters</Paragraph>
                <Paragraph><Text mark strong>Large oval soaking tubs</Text> in all master bathrooms</Paragraph>
                <Paragraph><Text mark strong>dual sinks</Text> with <Text mark strong>separate tub and shower</Text> in master bath.</Paragraph>
                <Paragraph>Recessed Lighting</Paragraph>
                <Paragraph>Central heat and air conditioning</Paragraph>
                <Paragraph>Glass shower enclosures</Paragraph>
            </Col>
            <Col sm={24} md={12}>
                <Divider>State-of-the-art Technology</Divider>
                <Paragraph>Advanced media box in each apartment for TV and Internet</Paragraph>
                <Paragraph><Text mark strong>Dual pane windows</Text> with efficient low energy glazing</Paragraph>
                <Paragraph>Separate water system and <Text mark strong>individual water heaters</Text> in each unit</Paragraph>
                <Paragraph><Text mark strong>Skylights</Text> in top floor units</Paragraph>
                <Paragraph>Controlled access entry with RFID and intercom</Paragraph>
                <Paragraph>Cameras at all entry and exits to building for added security</Paragraph>
                <Divider>Metro Art Quality</Divider>
                <Paragraph>Wide, well lit corridors decorated with artwork</Paragraph>
                <Paragraph>Secure, easy-access parking located beneath the building</Paragraph>
                <Paragraph>Two oversized elevators with tall ceilings from parking to all levels</Paragraph>
                <Paragraph>Impressive two story lobby</Paragraph>
                <Paragraph>Landscaped courtyard with fountain</Paragraph>
            </Col>
        </Row>
    </div>,
  images: [
    {
        src: 'assets/web/loft-1.jpg',
        caption: '',
        thumb: 'assets/web/loft-1.jpg'
    },
    {
        src: 'assets/web/loft-3.jpg',
        caption: '',
        thumb: 'assets/web/loft-3.jpg'
    },
    {
        src: 'assets/web/loft-6.jpg',
        caption: '',
        thumb: 'assets/web/loft-6.jpg'
    },
    {
        src: 'assets/web/apt-1-1.jpg',
        caption: '',
        thumb: 'assets/web/apt-1-1.jpg'
    },
    {
        src: 'assets/web/apt-1-6.jpg',
        caption: '',
        thumb: 'assets/web/apt-1-6.jpg'
    },
    {
        src: 'assets/web/apt-2-1.jpg',
        caption: '',
        thumb: 'assets/web/apt-2-1.jpg'
    },
    {
        src: 'assets/web/apt-2-4.jpg',
        caption: '',
        thumb: 'assets/web/apt-2-4.jpg'
    },
    {
        src: 'assets/web/apt-2-6.jpg',
        caption: '',
        thumb: 'assets/web/apt-2-6.jpg'
    },
    {
        src: 'assets/web/apt-2-7.jpg',
        caption: '',
        thumb: 'assets/web/apt-2-7.jpg'
    },
    {
        src: 'assets/web/apt-3-5.jpg',
        caption: '',
        thumb: 'assets/web/apt-3-5.jpg'
    },
  ]
}

export const AppData:ApplicationData = {
    footer: <div>
        <div>
            <Row>
                <Col md={24} xs={0}>
                    { ADDRESS_NAME } — {ADDRESS_1}, { ADDRESS_2 } — <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </Col>
                <Col md={0} xs={24}>
                    { ADDRESS_NAME }<br/>
                    { ADDRESS_1 }<br/>
                    { ADDRESS_2 }<br/>
                    <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </Col>
            </Row>
        </div>
        <div style={{fontSize: '80%', marginTop: '24px'}}>
            <div>{ ADDRESS_NAME } is located in or around SHERMAN OAKS, VAN NUYS, STUDIO CITY, VALLEY GLEN, VALLEY VILLAGE, ENCINO, LAKE BALBOA, BEVERLY GLEN, BEL AIR</div>
            <div>Thank You For Looking, We Look Forward to Helping You!</div>
        </div>
    </div>,
    sections: [
        {
            element: UI.Logo,
            props: {
                logo: <img style={{height: '50px'}} src="./assets/logo.jpg"/>,
                address: <div style={{lineHeight: 1}}>
                    <div style={{fontSize: '110%'}}>{ ADDRESS_NAME }</div>
                    <div style={{fontSize: '80%'}}>{ ADDRESS_1 }</div>
                    <div style={{fontSize: '80%'}}>{ ADDRESS_2 }</div>
                    <div style={{fontSize: '80%'}}><a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></div>
                </div>
            }
        },
        {
            isNav: true,
            element: UI.Nav,
            props: {
                logo: {
                    logo: <img style={{height: '50px'}} src="./assets/logo.jpg"/>,
                    address: <div style={{lineHeight: 1}}>
                        <div style={{fontSize: '110%'}}>{ ADDRESS_NAME }</div>
                        <div style={{fontSize: '80%'}}>{ ADDRESS_1 }</div>
                        <div style={{fontSize: '80%'}}>{ ADDRESS_2 }</div>
                        <div style={{fontSize: '80%'}}>{PHONE_NUMBER}</div>
                    </div>
                },
                rightSide: (
                    <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                ),
                rightSideModal: (
                    <div>
                        <Paragraph>
                            <UI.VirtualToursButton tours={tours}/>
                        </Paragraph>
                        <Paragraph>
                            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                        </Paragraph>
                    </div>
                )
            }
        },
        {
            element: UI.HeroImage,
            props: {
                image: './assets/web/hero.jpg'
            }
        },
        {
            element: UI.Tagline,
            props: {
                tagline: 'Spacious, luxury apartments in Sherman Oaks, CA. Restaurants and shops right downstairs.',
                undertag: <div>
                    <UI.VirtualToursButton tours={tours}/>
                    <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                </div>
            }
        },
        {
            element: UI.AboveFold,
            props: {
                images: [
                    './assets/web/apt-2-3.jpg',
                    './assets/web/apt-3-7.jpg',
                    './assets/web/apt-1-9.jpg',
                    './assets/web/laundry-2.jpg',
                    './assets/web/common-7.jpg'
                ]
            }
        },
        {
            element: UI.AboveFold,
            props: {
                flipped: true,
                images: [
                    './assets/web/common-6.jpg',
                    './assets/web/common-3.jpg',
                    './assets/web/loft-2.jpg',
                    './assets/web/common-9.jpg',
                    './assets/web/common-10.jpg'
                ]
            }
        },
        {
            element: UI.SectionTextCarousel,
            label: UI.NAV_ITEM.UNITS,
            nav: 'Units',
            props: {
                title: 'About Our Apartments',
                sections: [features]
            }
        },
        {
            element: UI.SectionTextCarousel,
            label: UI.NAV_ITEM.AMENITIES,
            nav: 'Amenities',
            props: {
                title: 'Community Amenities',
                sections: [amentities]
            }
        },
        {
            element: UI.SectionImages,
            label: UI.NAV_ITEM.FLOORPLANS,
            nav: 'Floorplans',
            props: {
                title: 'Floorplans',
                images: [
                    "./assets/floorplans/seville.png",
                    "./assets/floorplans/madrid.png",
                    "./assets/floorplans/granada.png",
                    "./assets/floorplans/valencia.png",
                    "./assets/floorplans/marbella.png",
                    "./assets/floorplans/barcelona.png"
                ]
            }
        },
        {
            element: UI.SectionTitle,
            label: UI.NAV_ITEM.CONTACT,
            nav: 'Tour',
            props: {
                title: 'Tour'
            }
        },
        {
            element: UI.SectionSideBySide,
            props: {
                leftSide: <div>
                    <h3>Schedule a tour with our on-site manager:</h3>
                    <div style={{marginTop: 24}}>
                        <h1 style={{}}><a href={`tel:${PHONE_NUMBER}`}>{ PHONE_NUMBER }</a></h1>
                        <div style={{fontSize: '80%'}}>Call anytime!</div>
                    </div>
                    <div style={{marginTop: 24}}>
                        <h1 style={{}}><a href={`mailto:${EMAIL}`}>{ EMAIL }</a></h1>
                        <div style={{fontSize: '80%'}}>Email us what you are looking for and some times that work for you.</div>
                    </div>
                    <div style={{marginTop: 24}}>
                        <Paragraph>
                            <UI.VirtualToursButton tours={tours}/>
                        </Paragraph>
                        <Paragraph>
                            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                        </Paragraph>
                    </div>
                </div>,
                rightSide: (
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.2980931890638!2d-118.44188198625221!3d34.15021625950286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c297d294316d77%3A0xcb13a5a1ebe274ae!2s14140%20Moorpark%20St%2C%20Sherman%20Oaks%2C%20CA%2091423!5e0!3m2!1sen!2sus!4v1597431693418!5m2!1sen!2sus" width="400" height="400" frameBorder={0} style={{border:"1px solid #CCC"}}></iframe>
                )
            }
        }
    ]
}